import React, { useEffect, useState } from "react";
import * as AiIcons from "react-icons/ai";
import * as FaIcons from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import haderBackground from "../../src/assets/img/black-texture.avif";
import mobBannerImage from "../../src/assets/img/mob-banner.jpg";
import { SidebarData } from "../Component/Sidebar/Sidebar-components/SidebarData";
import SubMenu from "../Component/Sidebar/Sidebar-components/SubMenu";
import logo from "../assets/img/logo.png";
import AddToCart from "./addTocart";
import { Badge, Box } from "@mui/material";
import { Container } from "react-bootstrap";

const SidebarWrap = styled.div`
  width: 100%;
  color: black !important; /* Set the text color to black */
`;

const Nav = styled.div`
  /* background-color: #2ec1ac; */
  background-image: url(${haderBackground});

  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const NavIcon = styled(Link)`
  // margin-left: 80rem;
  font-size: 1.5rem;
  // height: 60px;
  // display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const NavIcon1 = styled(Link)`
  margin-left: 22rem;
  font-size: 1.6rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: black;
`;
const Logo = styled.img`
  width: 250px;
  height: auto;
  position: absolute;
      padding: 0 30px 0 0;
`;
const Logo1 = styled.img`
  width: 180px;
  height: auto;
  position: absolute;
  left: 15px;
  top: 28px;
`;
const CartButton = styled(Link)`
  font-size: 1.1rem;
  color: #fff !important;
  text-decoration: none;
  // display: flex;
  align-items: center;
  position: relative;
  // left: -195px;
`;
const JoinButton = styled(Link)`
  font-size: 1rem;
  background: transparent;
  color: #fff !important;
  text-decoration: none;
  // display: flex;
  align-items: center;
  // margin-left: -180px; /* Adjust the margin as needed */
`;

const SidebarNav = styled.nav`
  background: rgb(2 96 193);
  width: 400px;
  background-image: url(${mobBannerImage});
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0px;
  overflow-y: auto;
  right: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 350ms;
  z-index: 9999999999;
  background-size: cover;
  background-position: center;
`;
const HeaderContainer = styled.div`
  position: relative;
  width: 100%;
  /* height: 100vh; */
  overflow: hidden;
`;

const HaderTwo = () => {
  const [sidebar, setSidebar] = useState(false);
  const token = window.localStorage.getItem("token");
  const showSidebar = () => setSidebar(!sidebar);
  const loggedinUserInfo = useSelector((state) => state.loggedinUserInfo);

  const handleSignOut = async () => {
    window.localStorage.removeItem("token");
  };

  const cartData = useSelector((state) => state.cart);

  const divStyle = {
    backgroundColor: "white", // Set background color to white
    borderRadius: "50%", // For rounded-full effect
    width: "15px", // Set your desired width
    height: "15px",
    // Set your desired height
  };

  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const timerId = setTimeout(() => {
      // Set showContent to true after the delay
      setShowContent(true);
    }, 5000);

    // Clear the timeout if the component unmounts before the delay is complete
    return () => clearTimeout(timerId);
  }, []);

  return (
    <div>
      <IconContext.Provider value={{ color: "#fff" }}>
        <HeaderContainer>
          <Nav>
            <Container className="headarContainer">
            <div>
            <Logo
              to="/jjj"
              className="nav-logo"
              src={logo}
              alt="logo"
              onClick={() => {
                window.location.href = "/";
              }}
              style={{ cursor: "pointer" }}
            />
            </div>
            <div className="cartMainDiv">

            {token ? (
              <CartButton className="nav-cart" to="#">
                <AddToCart />
                {showContent && loggedinUserInfo && (
                  <div>
                    {" "}
                    <p className="text-black text-center cart-num">
                      {" "}
                      {cartData ? cartData.length : null}{" "}
                    </p>{" "}
                  </div>
                )}
              </CartButton>
            ) : null}
            {token ? (
              <JoinButton to="/myProfile" className="frontpage-resp">
                {" "}
                {/* <FaUser className="nameresponsive" style={{ padding: "2px", fontSize: "13px" }} /> */}
                {loggedinUserInfo ? 
                <div style={{border:'1px solid', borderRadius:"50%", padding:"5px", display:"flex", justifyContent:"center", alignItems:"center", backgroundColor:"white", color:"grey", fontWeight:"bolder"}}>
                  {loggedinUserInfo.FName.split("")[0]+loggedinUserInfo.FName.split("")[1]}
                </div>
                 : "Login/Signup"}
              </JoinButton>
            ) : (
              <JoinButton to="/login" className="login-sign-btn frontpage-resp">
                {" "}
                <FaUser style={{ padding: "2px"}} />
                Login/Signup
              </JoinButton>
            )}
            <NavIcon to="#" className="toggle-div relative" id="mini-mobile-toggle-div">
              <FaIcons.FaBars onClick={showSidebar} className="toggle-btn" />
            </NavIcon>
            </div>
            </Container>
          </Nav>

          <SidebarNav sidebar={sidebar} className="nav-main-div">
            <SidebarWrap style={{ color: "#fff" }}>
              <Logo1 className="nav-logo" src={logo} alt="logo" />
              <NavIcon1 to="#" className="close-btn">
                <AiIcons.AiOutlineClose
                  onClick={showSidebar}
                  color="black"
                  style={{ color: "#fff", borderRadius: " 12px" }}
                />
              </NavIcon1>
              {SidebarData.map((item, index) => {
                return <SubMenu item={item} key={index} />;
              })}
            </SidebarWrap>
          </SidebarNav>
        </HeaderContainer>
      </IconContext.Provider>
    </div>
  );
};

export default HaderTwo;
